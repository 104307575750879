import Icon from '@/external/icon';
import RichText from '@/components/richText';
import { cn } from '@/lib/tailwind';
import { QuoteProps } from './types';
import Person from './components/Person';

const Quote = ({ content, person, className }: QuoteProps) => {
  return (
    <div
      className={cn(
        'grid border-2 border-whiteSmoke rounded-[8px] desktop-lg:rounded-[12px] shadow-gradientQuoteCard',
        'p-4 tablet-sm:p-8 desktop-lg:p-12',
        className,
      )}
    >
      <Icon
        icon="quote"
        className={cn(
          'w-8 tablet-sm:w-10 desktop-lg:w-12',
          'h-6 tablet-sm:h-8 desktop-lg:h-10',
          'mb-4 tablet-sm:mb-6',
        )}
      />
      <RichText
        className={cn(
          'prose text-mobile-sm/body-3-bold tablet-sm:text-tablet-sm/body-3-bold desktop-lg:text-desktop-large/body-2-bold prose-p:my-0',
          'mb-4 tablet-sm:mb-8 desktop-lg:mb-10',
        )}
        richText={content}
      />
      {person ? <Person {...person} /> : null}
    </div>
  );
};

export default Quote;
