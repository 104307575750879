import { cn } from '@/lib/tailwind';
import ImageAssetBlock from '@/components/ImageAssetBlock';
import Icon from '@/external/icon';
import Conditional from '@/components/Conditional';
import { PersonProps } from '../types';

type Props = PersonProps & {
  className?: string;
};

const Person = ({
  image,
  name,
  title,
  companyDivision,
  showPlaceholder = true,
  className,
}: Props) => {
  const commonImageClassNames = cn(
    'rounded-full aspect-square',
    'w-10 tablet-sm:w-16 desktop-sm:w-14 desktop-lg:w-[72px]',
  );
  return (
    <div
      className={cn(
        'flex items-center gap-3 tablet-sm:gap-4 desktop-sm:gap-6',
        className,
      )}
    >
      {image ? (
        <ImageAssetBlock
          asset={image}
          title={title}
          className={cn('object-cover', commonImageClassNames)}
        />
      ) : showPlaceholder ? (
        <Icon icon="profile" className={commonImageClassNames} />
      ) : null}
      <div className="flex-1">
        <div className="text-mobile-sm/body-3-bold tablet-sm:text-tablet-sm/body-3-bold desktop-lg:text-desktop-large/body-2-bold">
          {name}
        </div>
        <div className="text-mobile-sm/body-4 tablet-sm:text-tablet-sm/body-5 desktop-sm:text-tablet-sm/body-4 desktop-lg:text-desktop-large/body-3">
          <Conditional renderIf={title}>
            <div>{title}</div>
          </Conditional>
          <Conditional renderIf={companyDivision}>
            <div>{companyDivision}</div>
          </Conditional>
        </div>
      </div>
    </div>
  );
};

export default Person;
