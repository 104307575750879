import { Block, Inline } from '@contentful/rich-text-types';
import DescriptiveLogoGrid from '@/components/widgets/descriptiveLogoGrid';
import InlineShowcaseCta from '@/components/widgets/inlineShowcaseCta';
import TableOfContents from '@/components/widgets/tableOfContents';
import ImageBlock from '@/components/ImageBlock';
import CallToAction from '@/components/CallToAction';
import { convertCtaEntryToRefreshCtaEntry } from '@/utils/legacyEntryTransformers';
import CustomTOC from '@/components/widgets/customTOC';
import MathematicalFormulas from '@/components/widgets/mathematicalFormulas';
import CustomUnorderedList from '@/components/widgets/customUnorderedList';
import AccordionWidget from '@/components/widgets/accordionWidget';
import { Quote } from '@/components/quote';
import {
  CUSTOM_TOC_CONTENT_TYPE,
  DESCRIPTIVE_LOGO_GRID_CONTENT_TYPE,
  INLINE_SHOWCASE_CTA_CONTENT_TYPE,
  QUOTE_CONTENT_TYPE,
  MATHEMATICAL_FORMULAS_CONTENT_TYPE,
  TABLE_OF_CONTENTS_CONTENT_TYPE,
  REFRESH_IMAGE_CONTENT_TYPE,
  CUSTOM_UNORDERED_LIST_TYPE,
  LEGACY_CTA_CONTENT_TYPE,
  IFRAME_CONTENT_TYPE,
  ACCORDION_CONTENT_TYPE,
  LEGACY_ACCORDION_CONTENT_TYPE,
  LEGACY_QUOTE,
} from '../constants';
import Iframe from './Iframe';

import { LegacyAccordion } from './LegacyAccordion';

export const EmbeddedEntry = ({ node }: { node: Block | Inline }) => {
  const { target } = node?.data || {};

  switch (target?.contentType) {
    case DESCRIPTIVE_LOGO_GRID_CONTENT_TYPE: {
      return <DescriptiveLogoGrid {...target} />;
    }
    case INLINE_SHOWCASE_CTA_CONTENT_TYPE: {
      return <InlineShowcaseCta {...target} />;
    }
    case TABLE_OF_CONTENTS_CONTENT_TYPE: {
      return <TableOfContents {...target} />;
    }
    case QUOTE_CONTENT_TYPE: {
      return <Quote {...target} />;
    }
    case CUSTOM_TOC_CONTENT_TYPE: {
      return <CustomTOC {...target} />;
    }
    case MATHEMATICAL_FORMULAS_CONTENT_TYPE: {
      return <MathematicalFormulas {...target} />;
    }
    case REFRESH_IMAGE_CONTENT_TYPE: {
      return <ImageBlock image={target} />;
    }
    case CUSTOM_UNORDERED_LIST_TYPE: {
      return <CustomUnorderedList {...target} />;
    }
    case LEGACY_CTA_CONTENT_TYPE: {
      const ctaProps = convertCtaEntryToRefreshCtaEntry(target);
      return (
        <CallToAction {...ctaProps} containerClassName="block mx-auto w-fit" />
      );
    }
    case IFRAME_CONTENT_TYPE: {
      return <Iframe iFrameCode={target.iFrameText} />;
    }
    case ACCORDION_CONTENT_TYPE: {
      return (
        <AccordionWidget
          {...target}
          containerClassname="not-prose"
          accordionListClassname="py-0 tablet-sm:py-0 desktop-sm:py-0 desktop-lg:py-0"
          faqListClassName="desktop-sm:w-full desktop-lg:w-full"
        />
      );
    }
    case LEGACY_ACCORDION_CONTENT_TYPE:
      return <LegacyAccordion {...target} />;
    case LEGACY_QUOTE:
      return <Quote className="not-prose" {...target} />;
    default:
      return null;
  }
};
