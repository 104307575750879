export const DESCRIPTIVE_LOGO_GRID_CONTENT_TYPE = 'widgetDescriptiveLogoGrid';
export const INLINE_SHOWCASE_CTA_CONTENT_TYPE = 'templateInlineShowcaseCta';
export const TABLE_OF_CONTENTS_CONTENT_TYPE = 'embedTableOfContents';
export const QUOTE_CONTENT_TYPE = 'embedQuote';
export const CUSTOM_TOC_CONTENT_TYPE = 'tableOfContentHeadings';
export const MATHEMATICAL_FORMULAS_CONTENT_TYPE =
  'templateMathematicalFormulas';
export const REFRESH_IMAGE_CONTENT_TYPE = 'refreshImage';
export const BOX_CONTAINER_CONTENT_TYPE = 'boxContainer';
export const CUSTOM_UNORDERED_LIST_TYPE = 'customUnorderedList';
export const LEGACY_CTA_CONTENT_TYPE = 'cta';
export const IFRAME_CONTENT_TYPE = 'iFrame';
export const ACCORDION_CONTENT_TYPE = 'widgetAccordion';
export const LEGACY_ACCORDION_CONTENT_TYPE = 'templateAccordion';
export const LEGACY_QUOTE = 'quote';
